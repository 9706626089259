/* eslint-disable import/order */
import { SimpleForm, TextInput, SelectInput, EditContextProvider, useEditController } from 'react-admin';

/* @Components */
import {
  Typography,
  InputAdornment,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import { UrlCreateToolbar } from './UrlCreateToolbar';
import { ExpandMore, RemoveCircleOutline, FileCopy } from '@material-ui/icons';

/* @Hooks */
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useUrlEdit } from './hooks/useUrlEdit';
import { useDeeplinkLocalization } from './hooks/useDeeplinkLocalization';

/* @Helpers */
import { arrayToSelect, getCurrentDeeplinkLocalization, handleCopyLinkToClipboard } from './helpers';
import {
  productNameValidation,
  shortIdValidation,
  deepLinkValidation,
  redirectUrlValidation,
  secondaryCtaUrlValidation,
  mainCtaUrlValidation,
  localeValidation,
} from './validation';

import { APP_URL, API_SHORT_URL } from 'constants/config';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    display: 'flex',
  },
  formGroupTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  formWrap: {
    background: 'none !important',
    boxShadow: 'none !important',
  },
  fullGroupWrap: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  groupFooter: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  requiredInfo: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  accordionSummary: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  accordionDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  groupFooterIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const UrlEdit = (props) => {
  const classes = useStyles();
  const editControllerProps = useEditController(props);
  const { productNames, locales } = useSelector((state) => state.customData);
  const currentDeeplinkLocalization = useSelector(getCurrentDeeplinkLocalization(props.id));
  const {
    deeplinkLocalization,
    handleAddLocalization,
    handleAddLocale,
    handleRemoveLocalization,
  } = useDeeplinkLocalization(currentDeeplinkLocalization);
  const modifyUrl = useUrlEdit({
    successMessage: 'url was updated 🔥',
    currentUrlId: props.id,
    deeplinkLocalization,
  });
  const { basePath, record, redirect, resource, saving, version } = editControllerProps;

  return (
    <EditContextProvider value={editControllerProps}>
      <SimpleForm
        basePath={basePath}
        record={record}
        redirect={redirect}
        resource={resource}
        saving={saving}
        version={version}
        save={modifyUrl}
        toolbar={<UrlCreateToolbar handleAddLocalization={handleAddLocalization} />}
      >
        <>
          <Paper elevation={1} className={classes.requiredInfo}>
            <div>
              <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                Required info
              </Typography>
              <TextInput
                className={classes.input}
                label="Macpaw.app url"
                placeholder="Enter macpaw.app url"
                source="shortId"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{APP_URL}/</InputAdornment>,
                }}
                validate={shortIdValidation}
                autoComplete="off"
              />
              <SelectInput
                className={classes.input}
                label="Product name"
                placeholder="Enter product name"
                source="productName"
                choices={arrayToSelect(productNames)}
                validate={productNameValidation}
              />
              <Button color="primary" onClick={() => handleCopyLinkToClipboard('shortId', APP_URL)}>
                <FileCopy className={classes.groupFooterIcon} />
                Copy macpaw.app link
              </Button>
              <Button color="primary" onClick={() => handleCopyLinkToClipboard('shortId', API_SHORT_URL)}>
                <FileCopy className={classes.groupFooterIcon} />
                Copy macp.aw link
              </Button>
            </div>
          </Paper>
          <Accordion>
            <AccordionSummary id="url-form-redirect" className={classes.accordionSummary} expandIcon={<ExpandMore />}>
              <Typography>Redirect section</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.fullGroupWrap}>
                <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                  Redirect
                </Typography>
                <TextInput
                  className={classes.input}
                  label="Redirect to"
                  placeholder="fill redirect url"
                  source="url"
                  autoComplete="off"
                  validate={redirectUrlValidation}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id="url-form-deeplink" className={classes.accordionSummary} expandIcon={<ExpandMore />}>
              <Typography>Deeplink section (Default)</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div>
                <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                  Descriptions
                </Typography>
                <TextInput
                  className={classes.input}
                  label="H1 title"
                  placeholder="Enter h1 title"
                  source="h1Title"
                  autoComplete="off"
                  helperText="leave empty for the set default value. Default: Open your {product name}"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
                <TextInput
                  className={classes.input}
                  label="Header text"
                  placeholder="Enter header text"
                  source="headerText"
                  autoComplete="off"
                  helperText="leave empty for the set default value. Default: Click Allow in the pop-up window to open the app. If you don't see the pop-up or have closed it accidentally, press the button below to continue 👇"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
              </div>
              <div>
                <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                  Deeplink
                </Typography>
                <TextInput
                  className={classes.input}
                  label="macOS deep link"
                  placeholder="Enter macOS deep link"
                  source="params.macOs.deepLink"
                  autoComplete="off"
                  validate={deepLinkValidation}
                />
                <TextInput
                  className={classes.input}
                  label="Deep link button text"
                  placeholder="Enter deep link button text"
                  source="deepLinkCta"
                  autoComplete="off"
                  helperText="leave empty for the set default value. Default: Run Smart Scan"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
              </div>
              <div>
                <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                  Seo
                </Typography>
                <TextInput
                  className={classes.input}
                  label="og:title"
                  placeholder="Enter og:title"
                  source="params.seo.title"
                  autoComplete="off"
                />
                <TextInput
                  className={classes.input}
                  label="og:description"
                  placeholder="Enter og:description"
                  source="params.seo.description"
                  autoComplete="off"
                />
              </div>
              <div>
                <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                  Secondary CTA
                </Typography>
                <TextInput
                  className={classes.input}
                  label="Secondary CTA button title"
                  placeholder="Enter secondary CTA button title"
                  source="secondaryCta.title"
                  autoComplete="off"
                  helperText="Leave empty for the set default value. Default: Check Our Blog"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
                <TextInput
                  className={classes.input}
                  label="Secondary CTA button url"
                  placeholder="Enter secondary CTA button url"
                  source="secondaryCta.url"
                  autoComplete="off"
                  validate={secondaryCtaUrlValidation}
                  helperText="Leave empty for the set default value. Default: https://macpaw.com/blog"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
              </div>
              <div>
                <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                  Fallback
                </Typography>
                <Typography component="h6" gutterBottom>
                  Use in case, when deeplink can not open app
                </Typography>
                <TextInput
                  className={classes.input}
                  label="Main CTA button title"
                  placeholder="Enter CTA button title"
                  source="mainCta.title"
                  autoComplete="off"
                  helperText="Leave empty for the set default value. Default: Download CleanMyMac X"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
                <TextInput
                  className={classes.input}
                  label="Main CTA button url"
                  placeholder="Enter main cta button url"
                  source="mainCta.url"
                  autoComplete="off"
                  validate={mainCtaUrlValidation}
                  helperText="Leave empty for the set default value. Default: https://macpaw.com/cleanmymac/download"
                  FormHelperTextProps={{ variant: 'standard' }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          {deeplinkLocalization.map(({ id, locale }, index) => (
            <Accordion key={id}>
              <AccordionSummary
                id={`url-form-deeplink-${id}`}
                className={classes.accordionSummary}
                expandIcon={<ExpandMore />}
              >
                <Typography>Deeplink section ({locale ? locale : 'locale not set'})</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <div className={classes.fullGroupWrap}>
                  <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                    Locale
                  </Typography>
                  <SelectInput
                    className={classes.input}
                    label="Deeplink locale"
                    placeholder="Enter deeplink locale"
                    source={`locales[${index}].locale`}
                    choices={arrayToSelect(locales)}
                    validate={localeValidation}
                    onChange={(e) => handleAddLocale(e.target.value, id)}
                  />
                </div>
                <div>
                  <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                    Descriptions
                  </Typography>
                  <TextInput
                    className={classes.input}
                    label="H1 title"
                    placeholder="Enter h1 title"
                    source={`locales[${index}].h1Title`}
                    autoComplete="off"
                  />
                  <TextInput
                    className={classes.input}
                    label="Header text"
                    placeholder="Enter header text"
                    source={`locales[${index}].headerText`}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                    Deeplink
                  </Typography>
                  <TextInput
                    className={classes.input}
                    label="macOS deep link"
                    placeholder="Enter macOS deep link"
                    source={`locales[${index}].params.macOs.deepLink`}
                    autoComplete="off"
                    validate={deepLinkValidation}
                  />
                  <TextInput
                    className={classes.input}
                    label="Deep link button text"
                    placeholder="Enter deep link button text"
                    source={`locales[${index}].deepLinkCta`}
                    autoComplete="off"
                    FormHelperTextProps={{ variant: 'standard' }}
                  />
                </div>
                <div>
                  <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                    Seo
                  </Typography>
                  <TextInput
                    className={classes.input}
                    label="og:title"
                    placeholder="Enter og:title"
                    source={`locales[${index}].params.seo.title`}
                    autoComplete="off"
                  />
                  <TextInput
                    className={classes.input}
                    label="og:description"
                    placeholder="Enter og:description"
                    source={`locales[${index}].params.seo.description`}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                    Secondary CTA
                  </Typography>
                  <TextInput
                    className={classes.input}
                    label="Secondary CTA button title"
                    placeholder="Enter secondary CTA button title"
                    source={`locales[${index}].secondaryCta.title`}
                    autoComplete="off"
                    FormHelperTextProps={{ variant: 'standard' }}
                  />
                  <TextInput
                    className={classes.input}
                    label="Secondary CTA button url"
                    placeholder="Enter secondary CTA button url"
                    source={`locales[${index}].secondaryCta.url`}
                    autoComplete="off"
                    validate={secondaryCtaUrlValidation}
                    FormHelperTextProps={{ variant: 'standard' }}
                  />
                </div>
                <div>
                  <Typography component="h6" gutterBottom className={classes.formGroupTitle}>
                    Fallback
                  </Typography>
                  <TextInput
                    className={classes.input}
                    label="Main CTA button title"
                    placeholder="Enter CTA button title"
                    source={`locales[${index}].mainCta.title`}
                    autoComplete="off"
                    FormHelperTextProps={{ variant: 'standard' }}
                  />
                  <TextInput
                    className={classes.input}
                    label="Main CTA button url"
                    placeholder="Enter main cta button url"
                    source={`locales[${index}].mainCta.url`}
                    autoComplete="off"
                    validate={mainCtaUrlValidation}
                    FormHelperTextProps={{ variant: 'standard' }}
                  />
                </div>
                <footer className={classes.groupFooter}>
                  <Button color="secondary" onClick={() => handleRemoveLocalization(id)}>
                    <RemoveCircleOutline className={classes.groupFooterIcon} />
                    Remove
                  </Button>
                </footer>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      </SimpleForm>
    </EditContextProvider>
  );
};
